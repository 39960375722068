<template>
  <div class="myCourseRecordList">
    <table-list
      title="销售记录"
      :loading="loading"
      :search-form="searchForm"
      :data="recordList"
      :columns="columns(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div slot="filter" class="subTitle">
        <span class="textDes">当前累计销售金额：</span>
        <span class="textMon">{{ totalMoney }}</span>
      </div>
    </table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handletableExport,
    type: 'primary',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '购买人',
    prop: 'userName',
  },
]
// 表格列配置
const columns = _this => [
  {
    label: '订单编号',
    prop: 'orderId',
    minWidth: 150,
    render: (h, { row }) => {
      return [
        h(
          'div',
          {
            style: 'cursor:pointer;color:#FF7B33',
            on: {
              click: () => {
                _this.handleToDetail(row.orderId)
              },
            },
          },
          [h('div', row.orderId)],
        ),
      ]
    },
  },
  {
    label: '销售类型',
    prop: 'buyMethodExplain',
    minWidth: 150,
  },
  {
    label: '商品单价',
    prop: 'price',
    align: 'right',
  },
  {
    label: '销售数量',
    prop: 'totalNumber',
    align: 'right',
  },
  {
    label: '销售金额',
    prop: 'amount',
    align: 'right',
  },
  {
    label: '购买用户/机构',
    prop: 'payUserName',
    minWidth: 150,
  },
  {
    label: '购买时间',
    prop: 'payTime',
    sortable: 'sortable',
    minWidth: 150,
  },
]
import TableList from '@/components/TableList'
import { myCourseRecord, myCourseRecordExport } from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      loading: false,
      searchForm,
      columns,
      btns,
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      recordList: [],
      productId: '',
      totalMoney: '',
    }
  },
  mounted() {
    this.productId = this.$route.params.productId
    this.handleGetRecordList()
  },
  methods: {
    async handleGetRecordList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        myCourseRecord({ productId: this.productId, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.recordList = res.data.list
      this.pager.total = res.data.total
      this.totalMoney = res.extendData.totalAmount
    },
    async handletableExport() {
      const [res, err] = await to(
        myCourseRecordExport({ productId: this.productId, ...this.queryParams }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '销售记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.handleGetRecordList()
    },
    handleToDetail(orderId) {
      this.$router.push({ path: '/finance/detail', query: { id: orderId } })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleGetRecordList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleGetRecordList()
    },
  },
}
</script>
<style lang="scss" scoped>
.myCourseRecordList {
  .subTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid #e2e2e2;
    padding-left: 20px;
    line-height: 20px;
    .textDes {
      color: #909399;
    }
    .textMon {
      color: #ff7b33;
    }
  }
}
</style>
